<template>
  <div class="confirm-details">
    <p class="reference">
      {{ $t('product_page.orders.confirm_shipment_description') }}
    </p>

    <button @click="confirmShipment()">{{ $t('commons.confirm') }}</button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    order: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getLists: 'getLists',
    }),
  },
  methods: {
    ...mapActions({
      getAllTransactions: 'getAllTransactions',
      confirmDelivery: 'confirmDelivery',
    }),
    async confirmShipment() {
      const payload = {
        order: {
          order_uuid: this.order,
        },
      }

      await this.confirmDelivery(payload).finally(() => {
        this.getAllTransactions()
        this.$emit('close')
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.confirm-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.reference {
  font-size: 16px;
  color: white;
  margin-bottom: 20px;
}

button {
  background-color: $blue;
  color: white;
  margin: auto;
  border-radius: 40px;
  padding: 7px 16px;
  width: 200px;
  border: 0;
  font-size: 15px;
  text-align: center;
}
</style>
